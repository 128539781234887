/* eslint-disable no-unused-vars */
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Sponsors from "../components/sponsors"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import image from "../../static/macht.png"

const TicketLink = styled.a`
  color: white !important;
  box-sizing: border-box;
  margin-top: 1rem;
  display: inline-block;
  text-decoration: none;
  background: #6594d4;
  padding: 1rem;
`
const Paragraph = styled.p`
  padding: 0 1rem;
`
const ParagraphSmall = styled.p`
  padding: 0 1rem;
  a {
    color: #6594d4;
  }
  p {
    min-height: 0.1rem;
    display: block;
  }
`
const A = styled.a`
  color: #6594d4;
`
const Container = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
`
const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`
const Header = styled.img`
  height: 400px;
  width: 100vw;
  object-fit: contain;
  @media only screen and (max-width: 800px) {
    height: 200px;
  }
`
class Tickets extends React.Component {
  render() {
    const data = this.props.data.tickets.allTicketss.edges[0].node

    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Tickets" />
        <Container>
          <Columns>
            <Paragraph>
              <br />
              <strong>
                Tickets für GLOBART Academy 2021(inkl.Tickets für Ausstellung
                Tino Sehgal und Stiftsbesichtigung)
              </strong>
              <br />
              <br />
              Online: <br />
              <TicketLink href="https://tickets.globart-academy.at">
                Zum Ticketshop→
              </TicketLink>
              <br />
              <br />
              oder telefonisch bzw.per E - Mail im GLOBART Büro: <br />
              <A href="mailto:info@globart.at"> info @globart.at </A> <br />
              <A href="tel:+436764281728"> +43 676 4281728 </A>
              <br />
              <br />
              <strong>KEINE TAGESKASSE!!!</strong>
              <br />
              <br />
              <p>Auch gesondert erhältlich: </p>
              <strong>Tickets Ausstellung Tino Sehgal</strong>
              <p>
                für Erwachsene  €4,50 p.P. <br />
                für Studierende €3,50 p.P. <br />
                für Kinder (6-16 Jahre) €1,00 p.P.{" "}
              </p>
              <p>
                Erhältlich am Parkeingang, im Shop in der Nordbastei und an der
                Stiftsbesichtigungskassa 1. Stiftshof links.
              </p>
            </Paragraph>
            <ParagraphSmall>{RichText.render(data.description)}</ParagraphSmall>
          </Columns>
          <Sponsors lang="de-at" />
        </Container>
      </Layout>
    )
  }
}
export default Tickets

export const pageQuery = graphql`
  query TicketsQuery {
    tickets: prismic {
      allTicketss(lang: "de-at") {
        edges {
          node {
            description
            title
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
